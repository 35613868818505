import React from 'react';
import {useParams, } from 'react-router-dom';
import ContextProvider, { ContextConsumer } from '../../../utils/context';
import { getPackages } from '../../../api';
import {AVAILABLE_TABS} from "./tab-list";

export const PackageContext = React.createContext({});

export const checkSpecificPackage = (params) => {
    let location = window.location.pathname;
    const tabs = location.split('/')[2];
    let specificPackage = "";

    if (tabs === "prepaid"){
        specificPackage = "prepaidPackage";
    }
    else if (tabs === "corporate"){
        specificPackage = "corporatePackage";
    }
    else if (tabs === "timlive"){
        specificPackage = "livePackage";
    }

    return specificPackage
}

export const checkCreationPackage = (params) => {
    let location = window.location.pathname;
    const tabs = location.split('/')[3];
    let creationPackage = "";

    if (tabs.includes("-")){
        creationPackage = "creationPackage";
    }

    return creationPackage
}

export const PackageContextProvider = ({children}) => {
    let params = useParams();
    let specificPackage = checkSpecificPackage(params)
    let packageCreation = checkCreationPackage(params)

    if(specificPackage === undefined){
        console.log('yes')
    }

    if(packageCreation === "creationPackage"){
        return <ContextProvider request={()=> getPackages({scenarioId: params.packageId, [specificPackage]: true})}>
            <ContextConsumer>
            {({ response, triggerRequest}) =>{
                let _package = ((response || {}).packages || [])[0] || {};

                return (
                    <PackageContext.Provider value={{_package: _package, reloadPackage: triggerRequest}}>
                        {children}
                    </PackageContext.Provider>
                )
            }}
            </ContextConsumer>
        </ContextProvider>
    }else{
        return <ContextProvider request={()=> getPackages({packageId: params.packageId, [specificPackage]: true})}>
        <ContextConsumer>
            {({ response, triggerRequest}) =>{
                let _package = ((response || {}).packages || [])[0] || {};

                return (
                    <PackageContext.Provider value={{_package: _package, reloadPackage: triggerRequest}}>
                        {children}
                    </PackageContext.Provider>
                )
            }}
        </ContextConsumer>
    </ContextProvider>

    }
};




export default PackageContextProvider;