import React from "react";
import "./consult-info-style.css";

export const FILTER_KEYS_NAME_REPLACEMENT = {
  CCP1: "Quantidade de Parcelas",
  CC1: "Valor no Cartão de Crédito",
  CCA1: "Código de Autorização",
  CCP2: "Quantidade de Parcelas 2",
  CC2: "Valor no Cartão de Crédito 2",
  CCA2: "Código de Autorização 2",
  lastModificationDate: "Data da Última Modificação",
  COND: "Condição de Pagamento",
  cpf: "CPF",
  id: "ID do Pacote",
  TR_DATE: "Data da Transação",
  ENTRY: "Valor de Entrada",
  creationDate: "Data de Criação",
  DISCOUNT: "Desconto",
  phone: "Telefone",
  imei: "IMEI",
  username: "Usuário",
  status: "Status",
  Operation: "Operação",
  superStore: "Master Varejo",
  store: "Loja",
  "CED-CPF": "CPF do Cedente",
  MSISDN: "MSISDN",
  DDD: "DDD",
  statusDescription: "Descrição do Status",
  NAME: "Nome",
  BIRTHDATE: "Data de Nascimento",
  SESSIONID: "SessionID",
  "LIVE-PLANO": "Plano Live",
  "VOIP1-PLANO": "Plano VOIP 1",
  "VOIP2-PLANO": "Plano VOIP 2",
  "LIVE-PROTOCOLO": "Protocolo Live",
  PARCEIRO: "Parceiro",
  CPFCNPJ: "CNPJ",
  CNPJ: "CNPJ",
  PDV: "PDV",
  "SOCIO-DOC": "Documento Sócio",
};

export const PREPAID_KEY_VALUE_REPLACEMENT = {
  id: "ID do Pacote",
  TR_DATE: "Data da Transação",
  SESSIONID: "SessionID",
  cpf: "CPF",
  NAME: "Nome",
  BIRTHDATE: "Data de Nascimento",
  status: "Status",
};

export const LIVETIM_KEY_VALUE_REPLACEMENT = {
  "LIVE-PLANO": "Plano Live",
  "VOIP1-PLANO": "Plano VOIP 1",
  "VOIP2-PLANO": "Plano VOIP 2",
  cpf: "CPF",
  CNPJ: "CNPJ",
  "SOCIO-DOC": "Documento Sócio",
  TR_DATE: "Data da Transação",
  lastModificationDate: "Data da Última Modificação do Status",
  creationDate: "Data de Criação",
  statusDescription: "Descrição do Status",
  id: "ID do Pacote",
  "LIVE-PROTOCOLO": "Protocolo Live",
  status: "Status",
  username: "Usuário",
  PARCEIRO: "Parceiro",
};

export const FILTER_KEYS_EXPORT_NAME_PLACEMENT = {
  CCP1: "Quantidade de Parcelas Cartão 1",
  CC1: "Valor no Cartão de Crédito 1",
  CCA1: "Código de Autorização Cartão 1",
  CCP2: "Quantidade de Parcelas Cartão 2",
  CC2: "Valor no Cartão de Crédito 2",
  CCA2: "Código de Autorização Cartão 2",
  lastModificationDate: "Data da Última Modificação do Status",
  COND: "Condição de Pagamento",
  cpf: "CPF",
  CPFCNPJ: "CNPJ",
  TR_DATE: "Data da Transação",
  ENTRY: "Valor de Entrada",
  creationDate: "Data de Criação",
  DISCOUNT: "Desconto",
  phone: "MSISDN",
  imei: "IMEI",
  username: "Usuário criador do pacote",
  status: "Status detalhado",
  Operation: "Operação",
  superStore: "ID Grupo Econômico",
  store: "Código da Loja",
  "CED-CPF": "CPF do Cedente",
  MSISDN: "MSISDN",
  DDD: "DDD",
  statusDescription: "Histórico de Alterações",
  id: 'ID do pacote',
  CLIENTID: 'Tipo de cliente',
  SUBCLIENTID: 'Subtipo de cliente',
  CONTRATO: 'Número do Contrato',
  'NOVO-PLANO': 'Nome do Plano',
  FRONT: 'Canal de venda detalhado',
};

export const FILTER_DOCUMENT_KEYS_NAME_REPLACEMENT = {
  inv: "Nota Fiscal",
  cnt: "Termo de Adesāo",
  pmCntPlDv: "Termo de Permanência Plano/Aparelho",
  id: "Documento Pessoal",
  crCardRt: "Cartão de Crédito",
  crCardRt2: "Cartão de Crédito 2",
  rePrf: "Comprovante de Residencia",
  oldUserId: "Documento Pessoal do Cedente",
  cntfacil: "Contrato Fácil",
  CLI_TECONTRACT: "Termo de Contratação",
  PED_TECONTRACT: "Termo de Contratação",
  CLI_SOCONTRACT: "Contrato Social",
  PED_SOCONTRACT: "Contrato Social",
  CLI_IDENTITYID: "Carteira de Identidade",
  PED_IDENTITYID: "Carteira de Identidade",
  CLI_INVOICEOTH: "Fatura da Doadora",
  PED_INVOICEOTH: "Fatura da Doadora",
  CLI_PERMANENTC: "Contrato de Permanência",
  PED_PERMANENTC: "Contrato de Permanência",
  CLI_SERVICETER: "Termo ou Regulamento de Serviços",
  PED_SERVICETER: "Termo ou Regulamento de Serviços",
  CLI_ADMINATANO: "ATA de Nomeação dos Administradores",
  PED_ADMINATANO: "ATA de Nomeação dos Administradores",
  CLI_ATTORNEYLE: "Procuração",
  PED_ATTORNEYLE: "Procuração",
  CLI_MAILAUTHOR: "Autorização por e-mail do cliente para troca de aparelho",
  PED_MAILAUTHOR: "Autorização por e-mail do cliente para troca de aparelho",
  CLI_MAILSOLICI: "Termo de Autorização de Solicitação via e-mail",
  PED_MAILSOLICI: "Termo de Autorização de Solicitação via e-mail",
  CLI_BYLAWSCONS: "Estatuto Social Consolidado",
  PED_BYLAWSCONS: "Estatuto Social Consolidado",
  CLI_SHAREHOLDE: "ATA Assembléia Geral de Acionistas",
  PED_SHAREHOLDE: "ATA Assembléia Geral de Acionistas",
  CLI_EMPLOYEREQ: "Requerimento de Empresário",
  PED_EMPLOYEREQ: "Requerimento de Empresário",
  CLI_ADDRESSCOM: "Comprovante de Endereço",
  PED_ADDRESSCOM: "Comprovante de Endereço",
  CLI_CCMEICERTI: "CCMEI - Certifica da Condição de Microempreendedor Individual ou Alvará definitivo",
  PED_CCMEICERTI: "CCMEI - Certifica da Condição de Microempreendedor Individual ou Alvará definitivo",
  CLI_MEETINGEXT: "ATA da última reunião extraordinária do conselhor administrativo da Mitra Epscopal",
  PED_MEETINGEXT: "ATA da última reunião extraordinária do conselhor administrativo da Mitra Epscopal",
  CLI_ATOCONSTIT: "ATO Constitutivo do Cartório",
  PED_ATOCONSTIT: "ATO Constitutivo do Cartório",
  CLI_CCIRCERTIF: "Certificado de Cadastro de Imóvel Rural (CCIR)",
  PED_CCIRCERTIF: "Certificado de Cadastro de Imóvel Rural (CCIR)",
  CLI_ITRTAXOWNE: "Imposto sobre Propriedade Territorial Rural (ITR)",
  PED_ITRTAXOWNE: "Imposto sobre Propriedade Territorial Rural (ITR)",
  CLI_ATODELIBER: "ATO de Deliberação",
  PED_ATODELIBER: "ATO de Deliberação",
  CLI_TAXDOCUMEN: "Cartão de CNPJ com registro no Brasil",
  PED_TAXDOCUMEN: "Cartão de CNPJ com registro no Brasil",
  CLI_ATOEXTERIO: "ATO do poder executivo publicado no Diário Oficial ou emitido pelo Ministério das Relações Exteriores",
  PED_ATOEXTERIO: "ATO do poder executivo publicado no Diário Oficial ou emitido pelo Ministério das Relações Exteriores",
  CLI_ATOLIMITED: "ATO de Constituição de Sociedade Individual de Responsabilidade Limitada",
  PED_ATOLIMITED: "ATO de Constituição de Sociedade Individual de Responsabilidade Limitada",
  CLI_EIRELILTDA: "Última versão do Contrato Social da Empresa Limitada (Empresa que passou de Ltda para Eireli).",
  PED_EIRELILTDA: "Última versão do Contrato Social da Empresa Limitada (Empresa que passou de Ltda para Eireli).",
  CLI_EMAILACEPT: "E-mail de aceite do cliente",
  PED_EMAILACEPT: "E-mail de aceite do cliente",
  CLI_FTADOCUMEN: "FTA",
  PED_FTADOCUMEN: "FTA",
  CLI_CONDCOMNEG: "CONDIÇÕES COMERCIAIS DA NEGOCIAÇÃO",
  PED_CONDCOMNEG: "CONDIÇÕES COMERCIAIS DA NEGOCIAÇÃO",
  CLI_AUDIOCONFI: "Confirmação por Áudio",
  PED_AUDIOCONFI: "Confirmação por Áudio",
  CLI_PLACEPICTU: "Foto do Estabelecimento",
  PED_PLACEPICTU: "Foto do Estabelecimento",
  CLI_REPRPICTUR: "Foto do Representante Legal",
  PED_REPRPICTUR: "Foto do Representante Legal",
  CLI_ASSIGNPICT: "Assinatura",
  PED_ASSIGNPICT: "Assinatura",
  CLI_CONTRACORP: "Contrato Corporativo",
  PED_CONTRACORP: "Contrato Corporativo",
  CLI_TERMADESAO: "Termo de Adesão",
  PED_TERMADESAO: "Termo de Adesão",
  CLI_CREDITDOCS: "Documentos Financeiros - Crédito",
  PED_CREDITDOCS: "Documentos Financeiros - Crédito",
  CLI_ACEITEDIGI: "Termo de Aceite Digital",
  PED_ACEITEDIGI: "Termo de Aceite Digital",
  CLI_RESIDCOMPR: "Comprovante de Residência",
  PED_RESIDCOMPR: "Comprovante de Residência",
  CLI_IDENTIFYID: "Documento de Identificação",
  PED_IDENTIFYID: "Documento de Identificação",
  CLI_ADDCONTRAC: "Termo de Adesão",
  PED_ADDCONTRAC: "Termo de Adesão",
  CLI_ATAREPRNOM: "Ata da eleição dos Diretores / Representantes Legais vigentes / Nomeação de Administrador",
  PED_ATAREPRNOM: "Ata da eleição dos Diretores / Representantes Legais vigentes / Nomeação de Administrador",
  CLI_ATASSEMBPU: "Ata da ultima assembléia arquiva na da Junta Comercial ou publicada no DO",
  PED_ATASSEMBPU: "Ata da ultima assembléia arquiva na da Junta Comercial ou publicada no DO",
  CLI_ATARQRCPJU: "Ata da ultima assembléia arquiva no Registro Civil de Pessoas Jurídica",
  PED_ATARQRCPJU: "Ata da ultima assembléia arquiva no Registro Civil de Pessoas Jurídica",
  CLI_ATAREPORGA: "Ata de posse dos representantes legais ou doc similar que conste do respresent do órgão",
  PED_ATAREPORGA: "Ata de posse dos representantes legais ou doc similar que conste do respresent do órgão",
  CLI_ATAPRECGOV: "Ata de Registro de Preço (Governo)",
  PED_ATAPRECGOV: "Ata de Registro de Preço (Governo)",
  CLI_IDENTITYID: "Carteira de Identidade",
  PED_IDENTITYID: "Carteira de Identidade",
  CLI_CIDEXCECAO: "CI de Exceção",
  PED_CIDEXCECAO: "CI de Exceção",
  CLI_RESIDCOMPR: "Comprovante de Residência",
  PED_RESIDCOMPR: "Comprovante de Residência",
  CLI_CONTCHANCJ: "Contrato Chancelado pelo Jurídico (TIM x Cliente)",
  PED_CONTCHANCJ: "Contrato Chancelado pelo Jurídico (TIM x Cliente)",
  CLI_CONTRACORP: "Contrato Corporativo",
  PED_CONTRACORP: "Contrato Corporativo",
  CLI_CONTRCUSTP: "Contrato Customizado (Elaborado pelo Jurídico)  - Privado ",
  PED_CONTRCUSTP: "Contrato Customizado (Elaborado pelo Jurídico)  - Privado ",
  CLI_COPIAEDITA: "Cópia do Edital",
  PED_COPIAEDITA: "Cópia do Edital",
  CLI_DOCCPORTIN: "Documentação Cedente / Portabilidade",
  PED_DOCCPORTIN: "Documentação Cedente / Portabilidade",
  CLI_EMAILJUCRE: "Email Análise / Aprovação Jurídico e Crédito",
  PED_EMAILJUCRE: "Email Análise / Aprovação Jurídico e Crédito",
  CLI_GARCONTRAT: "Garantia do Contrato",
  PED_GARCONTRAT: "Garantia do Contrato",
  CLI_OFIAUTCOMP: "Oficio ou empenho de autorização emitido pelo órgão competente*",
  PED_OFIAUTCOMP: "Oficio ou empenho de autorização emitido pelo órgão competente*",
  CLI_PDFSIMULAD: "Pdf Tarifas Aprovado (Simulador)",
  PED_PDFSIMULAD: "Pdf Tarifas Aprovado (Simulador)",
  CLI_PROJCOMASS: "Projeto Comercial assinado",
  PED_PROJCOMASS: "Projeto Comercial assinado",
  CLI_PUBOFICVEN: "Publicação Oficial ou emitida pelo órgão que confirme a ajudicação da TIM como vencedora",
  PED_PUBOFICVEN: "Publicação Oficial ou emitida pelo órgão que confirme a ajudicação da TIM como vencedora",
  CLI_ACEITEDIGI: "Termo de Aceite Digital",
  PED_ACEITEDIGI: "Termo de Aceite Digital",
  CLI_ACTCEDENTE: "Termo de Aceite Digital Cedente",
  PED_ACTCEDENTE: "Termo de Aceite Digital Cedente",
  CLI_TERMNOMADM: "Termo de Nomeação do Administrador de Contrato",
  PED_TERMNOMADM: "Termo de Nomeação do Administrador de Contrato",
  CLI_TERMPERMAN: "Termo de Permanência***",
  PED_TERMPERMAN: "Termo de Permanência***",
  CLI_TERPORTASS: "Termo de Portabilidade Preenchido e Assinado",
  PED_TERPORTASS: "Termo de Portabilidade Preenchido e Assinado",
  CLI_TERMTRANSF: "Termo de Transferência de Titularidade",
  PED_TERMTRANSF: "Termo de Transferência de Titularidade",
  CLI_TERMAUTCON: "Termo que autoriza a contratação direta sem licitação",
  PED_TERMAUTCON: "Termo que autoriza a contratação direta sem licitação",
  CLI_ULTALTCONT: "Última alteração contratual arquivada na Junta Comercial",
  PED_ULTALTCONT: "Última alteração contratual arquivada na Junta Comercial",
  CLI_ATODELINCOR: "Ato deliberativo da incorporadora aprovando a incorporação, registrado no órgão competente (Junta Comercial)",
  PED_ATODELINCOR: "Ato deliberativo da incorporadora aprovando a incorporação, registrado no órgão competente (Junta Comercial)",
  CLI_ATODELFUS : "Ato deliberativo das entidades fusionadas decidindo sobre a constituição definitiva da nova entidade, registrada no órgão competente (Junta Comercial)",
  PED_ATODELFUS : "Ato deliberativo das entidades fusionadas decidindo sobre a constituição definitiva da nova entidade, registrada no órgão competente (Junta Comercial)",
  CLI_AUTOSERV : "Autorização ou Ordem de Serviço",
  PED_AUTOSERV: "Autorização ou Ordem de Serviço",
  CLI_OUTRO: "OUTRO",
  PED_OUTRO: "OUTRO"
};

export const FILTER_CIRCLES_KEYS_NAME_REPLACEMENT = {
  QUANTITY: "Quantidade",
  RECEIPTVALUE: "Valor do Cartão de Crédito",
  AUTH: "Código de Autorização",
  CPF: "CPF",
  OMOTHERNAME: "Nome da mãe (fonte oficial)",
  OBIRTHDATE: "Data de Nascimento (fonte oficial)",
  BIRTHDATE: "Data de Nascimento",
  MOTHERNAME: "Nome da mãe",
  MONAME: "Nome da mãe",
  ONAME: "Nome (fonte oficial)",
  NAME: "Nome",
  VALIDITYDATE: "Data de Validade",
  RNE: "RNE",
  WEB: "Pacote Web",
  ID: "Número de Identidade",
  IDNUMBER: "Número de Identidade",
  TR_DATE: "Data da Transação",
  CADESTITLE: "Título do Termo de Adesão",
  CPERMTITLE: "Título do Termo de Permanência",
  MSISDN: "Número de Telefone",
  DISCOUNT: "Desconto",
  ORGEXP: "Órgão Expedidor",
  UF: "Unidade Federativa",
  EXP: "Data de Expedição",
  PH2: "Pacote Fase 2",
  FACE: "Foto da Identidade",
  SIMILARITY: "Biometria Facial",
  LIVELINESS: "Prova de Vida",
  OLDUSERNAME: "Usuário Antigo",
  PLACEOFBIRTH: "Local de Nascimento",
  NEWRG: "Novo RG",
  "SERIAL-DEVICE": "Número de Série"
};

export const PACKAGE_STATUS = {
  GREEN: "green",
  YELLOW: "yellow",
  RED: "red",
  TO: "TO",
  KO: "KO",
};

export const ALLOWED_UPDATE_PACKAGE_STATUS = [
  PACKAGE_STATUS.YELLOW
];

export const UNALLOWED_UPDATE_PACKAGE_ROLEIDS = [
  "mobile_capture"
];

export const FILTER_VALUES_NAME_REPLACEMENT = {
  [PACKAGE_STATUS.GREEN]: <span id="green_score" className={"dot green"} />,
  [PACKAGE_STATUS.YELLOW]: <span id="yellow_score" className={"dot yellow"} />,
  [PACKAGE_STATUS.RED]: <span id="red_score" className={"dot red"} />,
  [PACKAGE_STATUS.TO]: <span id="to_score" className={"dot"} />,
  [PACKAGE_STATUS.KO]: <span id="ko_score" className={"dot"} />,
};

export const CONSULT_API_MAX_PAGE_SIZE = 10000;
